import { useMemo } from "react"
import styled from "styled-components"

import { endOfWeek, startOfWeek, subWeeks } from "date-fns"

import { GraphCard } from "src/components/Homes/DeviceDetails/Overview/GraphCard"
import { useFetchMoldInsightsByDate } from "src/data/moldInsights/moldInsightsQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { TClockType } from "src/data/user/user"
import { useTranslate } from "src/i18n/useTranslate"
import { mColors } from "src/ui/colors"
import { BarChart } from "src/ui/Graphs/BarChart"
import { Box } from "src/ui/Graphs/ChartTooltip"
import { TBarChartMultiSeriesData } from "src/ui/Graphs/graphTypes"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { formatDate } from "src/utils/l10n"

export function MoldInsightsGraphCard() {
  const user = useGetUser()
  const { tPlaceholder } = useTranslate()

  const fetchMoldRiskLevelGroup = useFetchMoldInsightsByDate({
    startDate: subWeeks(new Date(), 23),
    endDate: new Date(),
  })

  const hasMoldRiskData = (fetchMoldRiskLevelGroup.data?.length ?? 0) > 0

  const { lowMoldRisk, mediumMoldRisk, highMoldRisk } = useMemo(() => {
    const low: [number, number][] = []
    const medium: [number, number][] = []
    const high: [number, number][] = []

    fetchMoldRiskLevelGroup.data?.forEach((item) => {
      low.push([item.epoch_timestamp, item.number_of_homes_low])
      medium.push([item.epoch_timestamp, item.number_of_homes_medium])
      high.push([item.epoch_timestamp, item.number_of_homes_high])
    })

    return {
      lowMoldRisk: low,
      mediumMoldRisk: medium,
      highMoldRisk: high,
    }
  }, [fetchMoldRiskLevelGroup.data])

  const moldRiskData: TBarChartMultiSeriesData[] = [
    {
      name: tPlaceholder("High"),
      data: highMoldRisk,
      borderColor: mColors.systemErrorDark,
    },

    {
      name: tPlaceholder("Elevated"),
      data: mediumMoldRisk,
      borderColor: mColors.systemWarningDark,
    },
    {
      name: tPlaceholder("Low"),
      data: lowMoldRisk,
      borderColor: mColors.systemGoodDark,
    },
  ]

  return (
    <GraphCard
      title={tPlaceholder("Mold risk level overview")}
      isLoading={fetchMoldRiskLevelGroup.isLoading}
      hideGraphBorder={false}
      hidden={false}
      info={tPlaceholder(
        "See how mold risk level has changed across your units for the selected time period."
      )}
    >
      <BarChart
        dataSeriesType="multi"
        data={moldRiskData}
        timezone={""}
        stackSeries={true}
        clockType={user.clock_type}
        showLegend={false}
        borderRadius={0}
        seriesHoverBrightness={0.03}
        interactive={true}
        multiSeriesColors={[
          mColors.systemErrorLight,
          mColors.systemWarningLight,
          mColors.systemGoodLight,
        ]}
        xAxisOptions={{
          min: subWeeks(new Date(), 23).getTime(), // Fix in GXP 835
          max: new Date().getTime(), //  Fix in GXP 835
        }}
        yAxisOptions={{
          tickInterval: !hasMoldRiskData ? 5 : undefined,
          tickPositions: !hasMoldRiskData ? [0, 5, 10, 15, 20, 25] : undefined,
        }}
        tooltip={{
          decimals: 0,
          formatter: ({ date, points }) => {
            return (
              <Tooltip
                date={date}
                clockType={user.clock_type}
                points={{
                  high: points?.[0]?.y ?? 0,
                  elevated: points?.[1]?.y ?? 0,
                  low: points?.[2]?.y ?? 0,
                  total: points?.[0]?.total ?? 0,
                }}
              />
            )
          },
          unit: "",
        }}
      />
    </GraphCard>
  )
}

function Tooltip({
  date,
  clockType,
  points,
}: {
  date: Date
  clockType: TClockType
  points: { low: number; elevated: number; high: number; total: number }
}) {
  const { tPlaceholder } = useTranslate()

  const formattedStartDate = formatDate({
    date: startOfWeek(date).toISOString(),
    clockType: clockType,
    timezone: "",
    excludeTime: true,
  })

  const formattedEndDate = formatDate({
    date: endOfWeek(date).toISOString(),
    clockType: clockType,
    timezone: "",
    excludeTime: true,
  })

  const tooltipData = [
    { label: tPlaceholder("High mold risk"), value: points.high },
    { label: tPlaceholder("Elevated mold risk"), value: points.elevated },
    { label: tPlaceholder("Low mold risk"), value: points.low },
  ]

  return (
    <Box>
      <Header>
        <MText variant="bodyS">{`${formattedStartDate} - ${formattedEndDate}`}</MText>
      </Header>
      <Content>
        {tooltipData.map(({ label, value }) => (
          <TooltipContent
            key={label}
            units={value}
            total={points.total}
            label={label}
          />
        ))}
      </Content>
    </Box>
  )
}

function TooltipContent({
  units,
  total,
  label,
}: {
  units: number
  total: number
  label: string
}) {
  const { t, langKeys, tPlaceholder } = useTranslate()
  return (
    <div>
      <MText variant="subtitleS">
        <span>{tPlaceholder(label)}: </span>
        <span>{`${units} ${t(langKeys.unit, { count: units })}`}</span>
      </MText>
      <MText color="tertiary">{`(${((units / total) * 100).toFixed(0)}%)`}</MText>
    </div>
  )
}

const Header = styled(MText)`
  border-radius: 0.25rem 0.25rem 0 0;
  padding: ${spacing.S} ${spacing.M};
  background: ${mColors.primaryLight};
`

const Content = styled(MText)`
  display: grid;
  gap: ${spacing.XS};
  padding: ${spacing.S} ${spacing.M};
`
