export const moldInsightKeys = {
  all() {
    return ["mold-insights"] as const
  },

  moldRiskLevelGroup(filters: { startDate?: Date; endDate?: Date }) {
    const { startDate = "", endDate = "" } = filters

    return [...this.all(), startDate, endDate] as const
  },
} as const
