import styled from "styled-components"

import { MoldInsightsGraphCard } from "src/components/Reports/MoldInsights/MoldInsightsGraphCard"
import { MoldInsightsTable } from "src/components/Reports/MoldInsights/MoldInsightsTable"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { IBreadcrumb } from "src/ui/Breadcrumbs/Breadcrumbs"
import { BreadcrumbView } from "src/ui/Layout/BreadcrumbView"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function MoldInsights() {
  const { tPlaceholder, t, langKeys } = useTranslate()

  const breadcrumbs: IBreadcrumb[] = [
    {
      label: t(langKeys.report_plural),
      to: Routes.Reports.location(),
    },
    {
      label: tPlaceholder("Mold risk analytics"),
      to: Routes.MoldInsights.location(),
    },
  ]

  return (
    <div>
      <BreadcrumbView
        breadcrumbs={breadcrumbs}
        title={
          <MText variant="heading1">
            {tPlaceholder("Mold risk analytics")}
          </MText>
        }
        description={""}
      >
        <Box>
          <MoldInsightsGraphCard />

          <MoldInsightsTable
            title={tPlaceholder("Units with high or elevated mold risk")}
            info={tPlaceholder(
              "Units with high or elevated mold risk during the selected time period, sorted by the number of weeks with mold risk."
            )}
          />
        </Box>
      </BreadcrumbView>
    </div>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.XL2};
`
