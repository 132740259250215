import { useState } from "react"
import styled from "styled-components"

import { MoldRiskGraphCard } from "src/components/Homes/DeviceDetails/Overview/MoldRiskGraphCard"
import { Pager } from "src/components/Pager/Pager"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import { InfoSectionCard } from "src/components/Reports/MoldInsights/InfoSectionCard"
import { breakpoint } from "src/constants/breakpoints"
import { useFetchDevices } from "src/data/devices/queries/deviceQueries"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { THome } from "src/data/homes/types/homeTypes"
import { useFetchMoldRiskHomes } from "src/data/moldInsights/moldInsightsQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHomes } from "src/data/organizations/queries/homeQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { IconButton } from "src/ui/Button/IconButton"
import { mColors } from "src/ui/colors"
import { Container } from "src/ui/Container/Container"
import { MDialog } from "src/ui/Dialog/MDialog"
import { GridTable } from "src/ui/GridTable/GridTable"
import GraphPlotIcon from "src/ui/icons/graph-plot.svg"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MText } from "src/ui/MText"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { spacing } from "src/ui/spacing"

export function MoldInsightsTable({
  title,
  info,
}: {
  title: string
  info: string
}) {
  const limit = 5
  const { tPlaceholder, t, langKeys } = useTranslate()
  const { orgId } = useOrganization()

  const { offset, setOffset } = useUrlPager()

  const [selectedHome, setSelectedHome] = useState<THome | null>(null)
  const [selectedDevice, setSelectedDevice] = useState<TDevice | null>(null)

  const fetchMoldRiskHomes = useFetchMoldRiskHomes({ limit, offset })
  const moldRiskHomes = fetchMoldRiskHomes.data?.homes ?? []

  const deviceIds = moldRiskHomes.map((r) => r.device_id)
  const fetchDevices = useFetchDevices({
    orgId,
    filter: { ids: deviceIds },
    options: { enabled: deviceIds.length > 0 },
  })
  const devices = fetchDevices.data?.devices ?? []

  const moldRiskHomeIds = moldRiskHomes.map((r) => r.home_id)
  const fetchHomes = useFetchHomes({
    orgId,
    filters: { ids: moldRiskHomeIds },
    options: { enabled: moldRiskHomeIds.length > 0 },
  })
  const homes = fetchHomes.data?.homes ?? []

  const rows = moldRiskHomes.map((h) => (
    <>
      <RowLeft>
        <InternalLink to={Routes.Home.location(h.home_id)}>
          {h.homeName}
        </InternalLink>
        <MText color="tertiary">&bull; {h.location}</MText>
      </RowLeft>
      <RowRight>
        {tPlaceholder(`${h.number_of_weeks} weeks`)}
        <MTooltip title={tPlaceholder("View graph")}>
          <IconButton
            variant="subtle"
            Icon={GraphPlotIcon}
            size="small"
            color={"contrast"}
            onClick={() =>
              handleViewGraph({
                moldRiskDeviceId: h.device_id,
                moldRiskHomeId: h.home_id,
              })
            }
          />
        </MTooltip>
      </RowRight>
    </>
  ))

  const mobileRows = moldRiskHomes.map((h) => (
    <MobileRow key={h.home_id}>
      <MobileRowLeft>
        <InternalLink to={Routes.Home.location(h.home_id)}>
          {h.homeName}
        </InternalLink>
        <MText color="tertiary">&bull; {h.location}</MText>
      </MobileRowLeft>
      <MobileRowRight>
        {tPlaceholder(`${h.number_of_weeks} weeks`)}
        <MTooltip title={tPlaceholder("View graph")}>
          <IconButton
            variant="subtle"
            Icon={GraphPlotIcon}
            size="small"
            color={"contrast"}
            onClick={() =>
              handleViewGraph({
                moldRiskDeviceId: h.device_id,
                moldRiskHomeId: h.home_id,
              })
            }
          />
        </MTooltip>
      </MobileRowRight>
    </MobileRow>
  ))

  function handleViewGraph({
    moldRiskHomeId,
    moldRiskDeviceId,
  }: {
    moldRiskHomeId: string
    moldRiskDeviceId: string
  }) {
    setSelectedHome(homes.find((hl) => hl.home_id === moldRiskHomeId) ?? null)
    setSelectedDevice(
      devices.find((dl) => dl.device_id === moldRiskDeviceId) ?? null
    )
  }

  function handleClose() {
    setSelectedDevice(null)
    setSelectedHome(null)
  }

  return (
    <Container>
      <CardBox>
        <TableContainer>
          <Card>
            <Header>
              <MText variant="subtitle">{title}</MText>
              <MText variant="bodyS" color="secondary">
                {info}
              </MText>
            </Header>

            {moldRiskHomes.length > 0 ? (
              <GridTable
                rows={rows}
                mobileRows={mobileRows}
                templateColumns="1fr 1fr"
              />
            ) : (
              <MText variant="bodyS" color="tertiary">
                {tPlaceholder(
                  "Collecting data on units with a high or elevated risk of mold. This will take up to one week."
                )}
              </MText>
            )}
          </Card>

          <Pager
            limit={limit}
            offset={offset}
            setOffset={setOffset}
            totalCount={fetchMoldRiskHomes.data?.totalCount}
          />
        </TableContainer>
        <InfoSectionCard />
      </CardBox>

      {selectedDevice && selectedHome && (
        <MDialog
          open={true}
          onClose={() => handleClose()}
          hideClose
          confirmLabel={t(langKeys.close)}
          onConfirm={() => handleClose()}
        >
          <MoldRiskGraphCard
            hideGraphBorder={true}
            device={selectedDevice}
            home={selectedHome}
          />
        </MDialog>
      )}
    </Container>
  )
}

const CardBox = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 5fr) minmax(0, 1fr);
  gap: ${spacing.L};

  @container (${breakpoint.smallDown}) {
    grid-template-columns: 1fr;
  }
`

const Card = styled.div`
  border: 1px solid ${mColors.divider};
  border-radius: ${spacing.L};
  padding: ${spacing.XL};
`

const Header = styled.div<{ $hideGraphBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing.M};
`

const RowLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: ${spacing.XS2};
`

const RowRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: ${spacing.M};
`

const MobileRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${spacing.XS2};
`

const MobileRowLeft = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: ${spacing.XS2};
`

const MobileRowRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${spacing.S};
`

const TableContainer = styled.div`
  display: grid;
  gap: ${spacing.M};
`
