import { useQuery } from "@tanstack/react-query"
import { addDays, eachWeekOfInterval, subYears } from "date-fns"

import { moldInsightKeys } from "src/data/moldInsights/moldInsighQueryCache"

interface IHomesGroupedByMoldRiskLevel {
  number_of_homes_high: number
  number_of_homes_medium: number
  number_of_homes_low: number
  epoch_timestamp: number
}

export function useFetchMoldInsightsByDate({
  startDate,
  endDate,
}: {
  startDate: Date
  endDate: Date
}) {
  async function fetchMoldInsightsByDate() {
    return getMockedMoldInsightsData({ startDate, endDate })
  }

  return useQuery({
    queryKey: moldInsightKeys.moldRiskLevelGroup({ startDate, endDate }),
    queryFn: fetchMoldInsightsByDate,
  })
}

// Generate mocked mold insights data
function getMockedMoldInsightsData({
  startDate,
  endDate,
}: {
  startDate: Date
  endDate: Date
}): IHomesGroupedByMoldRiskLevel[] {
  if (startDate < subYears(new Date(), 1)) {
    return []
  }

  const weekIntervals = eachWeekOfInterval({
    start: startDate,
    end: endDate,
  })

  const mondays = weekIntervals.map((date) => addDays(date.setHours(14), 1))
  const numberOfHomes = 120

  return mondays.map((date) => {
    const randomRange = Array.from(
      { length: 3 },
      () => Math.floor(Math.random() * 3) + 1
    )
    const randomRangeSum = randomRange.reduce((acc, curr) => acc + curr, 0)

    return {
      number_of_homes_high: Math.floor(
        ((randomRange?.[0] ?? 0) / randomRangeSum) * numberOfHomes
      ),
      number_of_homes_medium: Math.floor(
        ((randomRange?.[1] ?? 0) / randomRangeSum) * numberOfHomes
      ),
      number_of_homes_low: Math.floor(
        ((randomRange?.[2] ?? 0) / randomRangeSum) * numberOfHomes
      ),
      epoch_timestamp: date.getTime(),
    }
  })
}

export function useFetchMoldRiskHomes({
  limit,
  offset,
}: {
  limit?: number
  offset?: number
}) {
  async function fetchMoldRiskHomes(): Promise<{
    homes: IMoldRiskHomes[]
    totalCount: number
  }> {
    return mockMoldRiskElevatedHomes({ limit, offset })
  }

  return useQuery({
    queryKey: ["moldRiskHomes", limit, offset], // Will be updated to the correct format
    queryFn: fetchMoldRiskHomes,
  })
}

/// Mocked mold risk elevated homes
interface IMoldRiskHomes {
  home_id: string
  location: string
  homeName: string
  device_id: string
  number_of_weeks: number
}

function mockMoldRiskElevatedHomes({
  limit = 5,
  offset = 0,
}: {
  limit?: number
  offset?: number
}): {
  homes: IMoldRiskHomes[]
  totalCount: number
} {
  const generateObjectId = (): string => {
    return [...Array(24)]
      .map(() => Math.floor(Math.random() * 16).toString(16))
      .join("")
  }

  const homes = sampleHmeNames.map((h) => ({
    home_id: generateObjectId(),
    location: h.split(" ").at(-1) ?? "",
    homeName: h,
    device_id: generateObjectId(),
    number_of_weeks: Math.ceil(Math.random() * 10),
  }))

  const filtered = homes.slice(offset, homes.length).slice(0, limit)

  return {
    homes: filtered,
    totalCount: sampleHmeNames.length,
  }
}

const sampleHmeNames = [
  "Willow Haven",
  "Maplewood Cottage",
  "Stonegate Manor",
  "Oakridge Estate",
  "Silverbrook Villa",
  "Hawthorne Lodge",
  "Briarwood House",
  "Evergreen Retreat",
  "Rosewood Haven",
  "Ravencrest Manor",
  "Bluebell Cottage",
  "Sunset Hollow",
  "Whispering Pines",
  "Ivywood House",
  "Moonlight Manor",
  "Cedar Ridge",
  "Lakeshore Haven",
  "Fernwood Cottage",
  "Meadowbrook Lodge",
  "Amberwood Estate",
]
