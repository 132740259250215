import styled from "styled-components"

import { HREF_MINUT_HELP_DECREASE_MOLD_RISK } from "src/constants/hrefs"
import { useTranslate } from "src/i18n/useTranslate"
import { mColors } from "src/ui/colors"
import LightBulbIcon from "src/ui/icons/lightbulb-on.svg"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function InfoSectionCard() {
  const { tPlaceholder } = useTranslate()
  return (
    <InfoCard>
      <div>
        <InfoHeader>
          <LightBulbIcon width={24} height={24} />
          <MText variant="subtitle">
            {tPlaceholder("How to decrease mold risk")}
          </MText>
        </InfoHeader>

        <MText variant="bodyS" color="secondary">
          {tPlaceholder(
            "Mold is more likely to grow in humid areas with poor ventilation."
          )}{" "}
          <ExternalLink
            href={HREF_MINUT_HELP_DECREASE_MOLD_RISK}
            showExternalIcon={false}
          >
            {tPlaceholder("Find out how to keep mold in check.")}
          </ExternalLink>
        </MText>
      </div>
    </InfoCard>
  )
}

const InfoCard = styled(MCard)`
  display: grid;
  padding: ${spacing.L};
  place-items: center;
  text-align: center;
  background-color: ${mColors.accentPrimaryLight};
`

const InfoHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${spacing.XS};
  padding-bottom: ${spacing.M};
`
